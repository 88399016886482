import React from 'react';

import VideoSection from 'components/VideoSection/VideoSection';
import externalLinks from 'utils/externalLinks';

import styles from './VideoModule.module.scss';

function VideoModule() {
  return (
    <div className={styles.video_module}>
      <div className={styles.video_module_container}>
        <VideoSection
          type="inline"
          autoPlay={false}
          muted={false}
          withCustomControls
          sources={[{ src: require('assets/videos/hse.mp4'), type: 'video/mp4' }]}
          poster={require('assets/images/hse-video-poster.png')}
        />
        <div className={styles.video_module_content}>
          <h3 className={styles.video_module_content_title}>
            Die Ganze Welt des <em>Shoppings</em>
          </h3>
          <a
            className={styles.video_module_content_link}
            href={externalLinks.onlineshop}
            target="_blank"
            rel="noopener noreferrer"
          >
            Onlineshop
          </a>
        </div>
      </div>
    </div>
  );
}

export default VideoModule;
