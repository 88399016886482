import React from 'react';
import classNames from 'classnames';

import CallToAction from '../shared/CallToAction/CallToAction';
import ParabolicShapeSvg from 'assets/icons/parabolic-shape.inline.svg';

import styles from './Tile.module.scss';

interface Props {
  image: {
    src: string;
    alt: string;
  };
  headingText: string;
  subHeadingText?: string;
  withShape?: boolean;
  variants?: {
    reverted?: boolean;
    bgColor?: 'white' | 'lightPink';
    ctaButton?: {
      url: string;
      text: string;
    };
  };
}

function Tile(props: Props) {
  const getWrapperStyles = () => ({
    [styles.tile_reverted]: props.variants?.reverted,
    [styles.tile_white]: props.variants?.bgColor === 'white',
    [styles.tile_lightPink]: props.variants?.bgColor === 'lightPink',
  });

  return (
    <div className={classNames(styles.tile, getWrapperStyles())}>
      <div className={styles.tile_content}>
        <h2
          className={styles.tile_content_title}
          dangerouslySetInnerHTML={{ __html: props.headingText }}
        />
        {props.subHeadingText && (
          <p
            className={styles.tile_content_description}
            dangerouslySetInnerHTML={{ __html: props.subHeadingText }}
          />
        )}
        {props.variants?.ctaButton && (
          <CallToAction
            type="primary"
            label={props.variants?.ctaButton?.text}
            url={props.variants?.ctaButton?.url}
          />
        )}
      </div>
      <div className={styles.tile_image}>
        <img {...props.image} />
      </div>
      {props.withShape && <ParabolicShapeSvg />}
    </div>
  );
}

export default Tile;
