import React from 'react';
import classNames from 'classnames';

import styles from './CallToAction.module.scss';

export enum CallToActionType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

interface CallToActionProps {
  label?: string;
  url?: string;
  type?: string;
}

const CallToAction: React.FC<CallToActionProps> = (props: CallToActionProps) => {
  return <a
    href={props.url}
    className={classNames(styles.button, {
      [styles.primary]: props.type === CallToActionType.PRIMARY.valueOf(),
      [styles.secondary]: props.type === CallToActionType.SECONDARY.valueOf(),
    })}
  >
    {props.label}
  </a>;
};
export default CallToAction;
