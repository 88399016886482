import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';

import 'scss/styles.scss';

import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero/Hero';
import Intro from '../components/Intro/Intro';
import Tile from '../components/Tile/Tile';
import VideoModule from '../components/VideoModule/VideoModule';
import externalLinks from '../utils/externalLinks';

function IndexPage() {
  const mainRef = useRef<HTMLDivElement>(null);

  return (
    <div id="main" ref={mainRef}>
      <Helmet title="HSE" />
      {/* Intro */}
      <Intro />
      {/* Hero section */}
      <Hero />
      {/* Video Module */}
      <VideoModule />
      {/* Tile */}
      <Tile
        withShape
        image={{
          src: require('assets/images/tile.png'),
          alt: 'Career apply caption',
        }}
        headingText="Jetzt bewerben und bei Uns <em>durchstarten</em>"
        variants={{
          bgColor: 'lightPink',
          ctaButton: {
            text: 'KARRIERE',
            url: externalLinks.karriere,
          },
        }}
      />
      {/* Footer */}
      <Footer
        links={[
          {
            text: 'ONLINESHOP',
            href: externalLinks.onlineshop,
          },
          {
            text: 'KARRIERE',
            href: externalLinks.karriere,
          },
          {
            text: 'Kontakt',
            href: '/contact/',
            target: '_self',
          },
        ]}
        socialMediaLinks={[
          {
            icon: {
              src: require('assets/icons/facebook.svg'),
              alt: 'Facebook page',
            },
            href: externalLinks.facebook,
          },
          {
            icon: {
              src: require('assets/icons/instagram.svg'),
              alt: 'Instagram profile',
            },
            href: externalLinks.instagram,
          },
          {
            icon: {
              src: require('assets/icons/pinterest.svg'),
              alt: 'Pinterest profile',
            },
            href: externalLinks.pinterest,
          },
          {
            icon: {
              src: require('assets/icons/youtube.svg'),
              alt: 'Youtube channel',
            },
            href: externalLinks.youtube,
          },
          {
            icon: {
              src: require('assets/icons/linkedin.svg'),
              alt: 'Linkedin profile',
            },
            href: externalLinks.linkedin,
          },
          {
            icon: {
              src: require('assets/icons/xing.svg'),
              alt: 'Xing profile',
            },
            href: externalLinks.xing,
          },
        ]}
        mobileAppsLinks={[
          {
            icon: {
              src: require('assets/images/apple-store.png'),
              alt: 'ios app',
            },
            href: externalLinks.ios_app,
          },
          {
            icon: {
              src: require('assets/images/google-play-store.png'),
              alt: 'android app',
            },
            href: externalLinks.android_app,
          },
        ]}
        subFooterContent={{
          legalLinks: [
            {
              text: 'Impressum',
              href: externalLinks.impressum,
            },
            {
              text: 'Datenschutz',
              href: externalLinks.datenschutz,
            },
          ],
          copyrightText: `Alle Rechte vorbehalten © HSE ${new Date().getFullYear()}`,
        }}
      />
    </div>
  );
}

export default IndexPage;
