import ParabolicShapeSvg from 'assets/icons/parabolic-shape.inline.svg';
import React from 'react';
import { defaultNavbarLinks } from '../../constants/constants';

import styles from './Hero.module.scss';

import Navbar from './Navbar/Navbar';

function Hero() {
  return (
    <div className={styles.wrapper}>
      <Navbar links={defaultNavbarLinks} />
      <div className={styles.text_container}>
        <h2>
          Wir machen Shopping zum <em>Erlebnis</em>
        </h2>
      </div>
      <ParabolicShapeSvg />
    </div>
  );
}

export default Hero;
